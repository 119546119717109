"use client";

import Landing from "@/components/home/landing/Landing";
import Body from "@/components/home/body/Body";

export default function Home() {

  
  return (
    <div className="home-container">
      <Landing />
      <Body />
    </div>
  );
}