"use client";

import React from 'react';
import { Row, Col, Card, Popover, Button, Tabs  } from "antd/lib";
import CheckedIcon from "../../../public/home/checked-icon.svg";
import CheckedRoundIcon from "../../../public/home/checked-round-icon.svg";
import CheckedRoundIconTest from "../../../public/home/checked-icon-project.svg";
import ArrowRight from '../../../public/home/arrow-right.svg';

import JoinIcon from "../../../public/home/body-join.svg";
import InvestIcon from "../../../public/home/body-invest.svg";
import GrowIcon from "../../../public/home/body-grow.svg";
import LoopImg from "../../../public/home/body-loop.svg";

import BenefitBg from "../../../public/home/benefits-bg-img.svg";

import EnergyMetric from "../../../public/home/metric-energy.svg";
import ImpactMetric from "../../../public/home/metric-impact.svg";
import TimeMetric from "../../../public/home/metric-time.svg";

import TabCombinedCert from "../../../public/home/combinedCert.svg";
import TabCommunityImg from "../../../public/home/combinedCert.svg";
// import TabTechnologyImg from "../../../public/home/combinedCert.svg";

import Image from "next/image";

import "./body.styles.scss";

const { Meta } = Card;

const tabData = [
  {
    label: 'Ownership',
    content: (
      <div className="tab-ownership-container">
        <Row className="tab-ownership-row-content">
          <Col
            xs={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            lg={{ span: 24 }}
            className="tab-ownership-col-container"
          >
            <Col
              xs={{ span: 14 }}
              md={{ span: 14 }}
              sm={{ span: 14 }}
              lg={{ span: 14 }}
              className="tab-ownership-col-text"
            >
              <h3 className="tab-ownership-title">
                Your Stake in a Greener World
              </h3>
              <p>
                When you hold a Greg Certificate, you are not just investing—you are owning. Each Greg Certificate represents a tangible stake in a renewable farm, giving you equity in clean energy production and a voice in the energy revolution.
              </p>    
              <Button>Be an Owner</Button>
            </Col>
            <Col
              xs={{ span: 10 }}
              md={{ span: 10 }}
              sm={{ span: 10 }}
              lg={{ span: 10 }}
            >
              <TabCombinedCert className="tab-ownership-content-img"/>
            </Col>
          </Col>
        </Row>
      </div>
    ),
  },
  {
    label: 'Technology',
    content: (
      <div className="tab-ownership-container">
        <Row className="tab-ownership-row-content">
          <Col
            xs={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            lg={{ span: 24 }}
            className="tab-ownership-col-container"
          >
            <Col
              xs={{ span: 14 }}
              md={{ span: 14 }}
              sm={{ span: 14 }}
              lg={{ span: 14 }}
              className="tab-ownership-col-text"
            >
              <h3 className="tab-ownership-title">
                Power Meets Transparency
              </h3>
              <p>
                Our DLT Platform seamlessly connects you to real-time data. Track energy output, monitor your farm performance, and watch your investment grow—all from the palm of your hand. It is the future of renewable energy, made simple.
              </p>    
              <Button>Discover Greg Stack</Button>
            </Col>
            <Col
              xs={{ span: 10 }}
              md={{ span: 10 }}
              sm={{ span: 10 }}
              lg={{ span: 10 }}
            >
              <Image
                src="/home/solar-panel-phase.png"
                width={600}
                height={280}
                alt="illustration solar farm"
                className="tab-technology-img"
              />
            </Col>
          </Col>
        </Row>
      </div>
    ),
  },
  {
    label: 'Community',
    content: (

              <div className="tab-ownership-container">
        <Row className="tab-ownership-row-content">
          <Col
            xs={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            lg={{ span: 24 }}
            className="tab-ownership-col-container"
          >
            <Col
              xs={{ span: 14 }}
              md={{ span: 14 }}
              sm={{ span: 14 }}
              lg={{ span: 14 }}
              className="tab-ownership-col-text"
            >
              <h3 className="tab-ownership-title">
                Join a Movement That Gives Back
              </h3>
              <p>
                Being a Greg means more than just owning equity—it means building a brighter future for all. From supporting local reforestation projects to empowering nearby communities, your investment drives lasting change where it matters most.
              </p>    
              <Button>Join Greg Movement</Button>
            </Col>
            <Col
              xs={{ span: 10 }}
              md={{ span: 10 }}
              sm={{ span: 10 }}
              lg={{ span: 10 }}
            >
              <Image
                src="/home/tab-community.jpg"
                width={600}
                height={300}
                alt="illustration solar farm"
                className="tab-community-img"
              />
            </Col>
          </Col>
        </Row>
        </div>
      
    ),
  },
];

const benefitTabsItems = tabData.map((tab, index) => ({
  label: tab.label,
  key: String(index + 1),
  children: tab.content,
}));

export default function Body() {

  return (
    <div className="body-container">
      <Row className="body-row">
        <Col
          xs={{ span: 24 }}
          md={{ span: 24 }}
          sm={{ span: 24 }}
          lg={{ span: 24 }}
        >
          <h1 className="body-title">
            <div className="body-title-colour"> Own a Piece of a </div> Greener Future
          </h1>
        </Col>
        <Col
          xs={{ span: 24 }}
          md={{ span: 24 }}
          sm={{ span: 24 }}
          lg={{ span: 24 }}
          className="body-banner"
        >
          <Col
            xs={{ span: 8 }}
            md={{ span: 8 }}
            sm={{ span: 8 }}
            lg={{ span: 8 }}
            className="body-banner-checked"
          >
            <CheckedIcon className="body-checked-icon" />
            <h2 className="body-checked-text">Your Voice in Clean Energy</h2>
          </Col> 
          <Col
            xs={{ span: 8 }}
            md={{ span: 8 }}
            sm={{ span: 8 }}
            lg={{ span: 8 }}
            className="body-banner-checked"
          >
            <CheckedIcon className="body-checked-icon" />
            <h2 className="body-checked-text">Meaningful Ownership</h2>
          </Col> 
          <Col
            xs={{ span: 8 }}
            md={{ span: 8 }}
            sm={{ span: 8 }}
            lg={{ span: 8 }}
            className="body-banner-checked"
          >
            <CheckedIcon className="body-checked-icon" />
            <h2 className="body-checked-text">Impactful Investment</h2>
          </Col> 
        </Col>
      </Row>

      {/* BODY ROW FOR INTRO CARDS */}
      <Row className="body-row-card-intro" gutter={16}>
        <LoopImg className="loop-background" />
        <Col
          xs={{ span: 8 }}
          md={{ span: 8 }}
          sm={{ span: 8 }}
          lg={{ span: 8 }}
        >
          <Card
            className="body-card"
            cover={
              <JoinIcon className="body-solar-panels-card" />
            }
          >
            <Meta
              title="Be a Greg, Own the Future"
              description="Step into the Greg community with your Greg Certificate—real ownership in renewable farms."
            />
          </Card>
        </Col>
        <Col
          xs={{ span: 8 }}
          md={{ span: 8 }}
          sm={{ span: 8 }}
          lg={{ span: 8 }}
        >
          <Card
            className="body-card"
            cover={
              <InvestIcon className="body-solar-panels-card" />
            }
          >
            <Meta
              title={
                <div style={{color: "#13c08c"}}>Empower The Planet</div>
              }
              description="Your investment fuels clean energy that is shared and sold, creating a legacy beyond profits."
            />
          </Card>
        </Col>
        <Col
            xs={{ span: 8 }}
            md={{ span: 8 }}
            sm={{ span: 8 }}
            lg={{ span: 8 }}
        >
          <Card
            className="body-card"
            cover={
              <GrowIcon className="body-solar-panels-card" />
            }
          >
            <Meta
              title="See the Impact"
              description="Watch your impact grow as profits return from every watt of clean energy we produce."
            />
          </Card>
        </Col>
      </Row>

      {/* BODY ROW FOR BENEFITS CARD */}
      <Row className="body-row-card-benefits">
        <BenefitBg className="benefits-bg-img"/>
        <Col
          xs={{ span: 24 }}
          md={{ span: 24 }}
          sm={{ span: 24 }}
          lg={{ span: 24 }}
        >
          <Card
            className="body-card-benefits"
          >
            <Meta
              title="Own. Empower. Belong."
              description={
                <Row>
                  <Col
                    xs={{ span: 8 }}
                    md={{ span: 8 }}
                    sm={{ span: 8 }}
                    lg={{ span: 8 }}
                    className="body-card-benefits-checked"
                  >
                    <CheckedRoundIcon className="body-checked-round-icon" />
                    <h2 className="body-checked-round-text">Transparent Holding</h2>
                  </Col>
                  <Col
                    xs={{ span: 8 }}
                    md={{ span: 8 }}
                    sm={{ span: 8 }}
                    lg={{ span: 8 }}
                    className="body-card-benefits-checked"
                  >
                    <CheckedRoundIcon className="body-checked-round-icon" />
                    <h2 className="body-checked-round-text">Greater Accessibility</h2>
                  </Col>
                  <Col
                    xs={{ span: 8 }}
                    md={{ span: 8 }}
                    sm={{ span: 8 }}
                    lg={{ span: 8 }}
                    className="body-card-benefits-checked"
                  >
                    <CheckedRoundIcon className="body-checked-round-icon" />
                    <h2 className="body-checked-round-text">Sustainable Impact</h2>
                  </Col>
                </Row>
              }
            />
              <div className="body-card-benefits-content">
                <h3 className="body-card-benefits-content-text">
                  Greg Certificates make renewable energy ownership accessible, secure, and meaningful, blending the power of technology with a common mission to drive change, transparency, and access to clean energy for all.
              </h3>
                <Tabs
                  defaultActiveKey="1"
                  items={benefitTabsItems}
                  type="card"
                  centered
                />
              </div>
          </Card>
        </Col>
      </Row>

      {/* BODY ROW PROJECTS INTRO */}
      <Row className="body-row-project-container">
        <Col
          xs={{ span: 24 }}
          md={{ span: 24 }}
          sm={{ span: 24 }}
          lg={{ span: 24 }}
          className="ant-col-project-title"
        >
          <h1 className="body-card-title">
            What are we building?
          </h1>
        </Col>
         {/* Phase 1 */}
        <Col
          xs={{ span: 24 }}
          md={{ span: 24 }}
          sm={{ span: 24 }}
          lg={{ span: 24 }}
          className="ant-col-solar-project"
        >
          <Col
            xs={{ span: 14 }}
            md={{ span: 14 }}
            sm={{ span: 14 }}
            lg={{ span: 14 }}
          >
          <Card
            className="body-card-project"
            title={
              <div className="body-card-project-text">
                <h5 className="body-card-project-phase">Phase I</h5>
                <h3 className="body-card-project-title">
                  Harness the Sun with Greg
                </h3>
                <h3 className="body-card-project-intro">
                  Our solar farms capture the sun energy to power homes, schools, and communities. Each farm you own with a Greg Certificate stands as a symbol of change that is tangible, efficient, and accessible.
                </h3>
                <div className="body-card-project-benefits">
                  <div className="body-card-project-point">
                    <CheckedRoundIconTest className="body-card-point-icon" />
                    <h4>Quick to Build</h4>
                  </div>
                  <div className="body-card-project-point">
                    <CheckedRoundIconTest className="body-card-point-icon" />
                    <h4>Low Maintenance</h4>
                  </div>
                  <div className="body-card-project-point">
                    <CheckedRoundIconTest className="body-card-point-icon" />
                    <h4>Direct Positive Impact</h4>
                  </div>
                </div>
              </div>
            }
          >
            <Meta
              description={
                <div className="body-card-solar-metrics">
                  <Col
                    xs={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    lg={{ span: 24 }}
                  >
                    <Col
                      xs={{ span: 8 }}
                      md={{ span: 8 }}
                      sm={{ span: 8 }}
                      lg={{ span: 8 }}
                      className="ant-col-solar-metric"
                    >
                      <Popover placement="bottomLeft" title="Energy Output" content="500 MW/year">
                        <div className="solar-metric-sphere">
                          <span className="solar-metric-shadow"></span>
                          <EnergyMetric className="energy-metric-icon" />  
                        </div>
                      </Popover>  
                    </Col>
                    <Col
                      xs={{ span: 8 }}
                      md={{ span: 8 }}
                      sm={{ span: 8 }}
                      lg={{ span: 8 }}
                      className="ant-col-solar-metric"
                    >
                      <Popover placement="bottomLeft"
                        title="Impact"
                        content="250 Co2 tonnes offset from atmosphere"
                      >
                        <div className="solar-metric-sphere">
                          <span className="solar-metric-shadow"></span>
                          <ImpactMetric className="impact-metric-icon" />  
                        </div>
                      </Popover>  
                    </Col>  
                    <Col
                      xs={{ span: 8 }}
                      md={{ span: 8 }}
                      sm={{ span: 8 }}
                      lg={{ span: 8 }}
                      className="ant-col-solar-metric"
                    >  
                      <Popover placement="bottomLeft" title="Construction Time" content="Operational after 1 year of construction.">
                        <div className="solar-metric-sphere">
                          <span className="solar-metric-shadow"></span>
                          <TimeMetric className="time-metric-icon" />  
                        </div>
                      </Popover>  
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      lg={{ span: 24 }}
                      className="ant-col-learn-more-btn"  
                    >  
                      <Button
                        className='solar-metric-learn-btn'
                        icon={<ArrowRight />}
                        iconPosition="end"
                      >
                        Learn More
                      </Button>    
                    </Col>  
                  </Col>
                    
                </div>
              }
            />
          </Card>
          </Col>
          <br /><br />
          <Col
            xs={{ span: 10 }}
            md={{ span: 10 }}
            sm={{ span: 10 }}
            lg={{ span: 10 }}
            className="ant-col-solar-img"
          >
            <Image
              src="/home/solar-panel-phase.png"
              width={600}
              height={600}
              alt="illustration solar farm"
              className="body-card-project-img"
            />
          </Col>
        </Col>

         {/* Phase 2 */}
        <Col
          xs={{ span: 24 }}
          md={{ span: 24 }}
          sm={{ span: 24 }}
          lg={{ span: 24 }}
          className="ant-col-hydro-project"
        >
          <Col
            xs={{ span: 10 }}
            md={{ span: 10 }}
            sm={{ span: 10 }}
            lg={{ span: 10 }}
            className="ant-col-hydro-img"
          >
            <Image
                src="/home/hydro-power-phase.png"
                width={600}
                height={600}
                alt="illustration hydro farm"
                className="body-card-project-img"
            />
          </Col>

          <Col
            xs={{ span: 14 }}
            md={{ span: 14 }}
            sm={{ span: 14 }}
            lg={{ span: 14 }}
          >
            <Card
              className="body-card-project"
              title= {
                <div className="body-card-project-text">
                  <h5 className="body-card-project-phase">Phase II</h5>
                  <h3 className="body-card-project-title">
                    Ride the Rivers with Greg
                  </h3>
                  <h3 className="body-card-project-intro">
                    From slow rivers to steady power, our hydropower projects turn nature flow into constant energy. Your Greg Certificate here connects you to the earth natural rhythm, a reliable power source with a purpose.
                  </h3>
                  <div className="body-card-project-benefits">
                    <div className="body-card-project-point">
                      <CheckedRoundIcon className="body-card-point-icon" />
                      <h4>Continuous Power</h4>
                    </div>
                    <div className="body-card-project-point">
                      <CheckedRoundIcon className="body-card-point-icon" />
                      <h4>Reliable Turbines</h4>
                    </div>
                    <div className="body-card-project-point">
                      <CheckedRoundIcon className="body-card-point-icon" />
                      <h4>Earth-Conscious</h4>
                    </div>
                  </div>
                </div>
              }
            >
            <Meta
              description={
                <div className="body-card-solar-metrics">
                  <Col
                    xs={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    lg={{ span: 24 }}
                  >
                    <Col
                      xs={{ span: 8 }}
                      md={{ span: 8 }}
                      sm={{ span: 8 }}
                      lg={{ span: 8 }}
                      className="ant-col-solar-metric"
                    >
                      <Popover placement="bottomLeft" title="Energy Output" content="500 MW/year">
                        <div className="solar-metric-sphere">
                          <span className="solar-metric-shadow"></span>
                          <EnergyMetric className="energy-metric-icon" />  
                        </div>
                      </Popover>  
                    </Col>
                    <Col
                      xs={{ span: 8 }}
                      md={{ span: 8 }}
                      sm={{ span: 8 }}
                      lg={{ span: 8 }}
                      className="ant-col-solar-metric"
                    >
                      <Popover placement="bottomLeft"
                        title="Impact"
                        content="250 Co2 tonnes offset from atmosphere"
                      >
                        <div className="solar-metric-sphere">
                          <span className="solar-metric-shadow"></span>
                          <ImpactMetric className="impact-metric-icon" />  
                        </div>
                      </Popover>  
                    </Col>  
                    <Col
                      xs={{ span: 8 }}
                      md={{ span: 8 }}
                      sm={{ span: 8 }}
                      lg={{ span: 8 }}
                      className="ant-col-solar-metric"
                    >  
                      <Popover placement="bottomLeft" title="Construction Time" content="Operational after 1 year of construction.">
                        <div className="solar-metric-sphere">
                          <span className="solar-metric-shadow"></span>
                          <TimeMetric className="time-metric-icon" />  
                        </div>
                      </Popover>  
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      lg={{ span: 24 }}
                      className="ant-col-learn-more-btn"  
                    >  
                      <Button
                        className='solar-metric-learn-btn'
                        icon={<ArrowRight />}
                        iconPosition="end"
                      >
                        Learn More
                      </Button>    
                    </Col>  
                  </Col>
                    
                </div>
              }
            />
            </Card>
          </Col>
        </Col>
        {/* Phase 3 */}
        <Col
          xs={{ span: 24 }}
          md={{ span: 24 }}
          sm={{ span: 24 }}
          lg={{ span: 24 }}
          className="ant-col-forest-project"
        >
          <Col
            xs={{ span: 14 }}
            md={{ span: 14 }}
            sm={{ span: 14 }}
            lg={{ span: 14 }}
          >
          <Card
            className="body-card-project"
            title={
              <div className="body-card-project-text">
                <h5 className="body-card-project-phase">Phase III</h5>
                <h3 className="body-card-project-title">
                  Rebuild the World with Greg
                </h3>
                <h3 className="body-card-project-intro">
                  Together, we are not just powering homes—we are also regrowing forests. Every Greg Certificate empowers reforestation, supporting biodiversity, and rebalancing our ecosystems.
                </h3>
                <div className="body-card-project-benefits">
                  <div className="body-card-project-point">
                    <CheckedRoundIconTest className="body-card-point-icon" />
                    <h4>Nature-Driven</h4>
                  </div>
                  <div className="body-card-project-point">
                    <CheckedRoundIconTest className="body-card-point-icon" />
                    <h4>Low-Cost</h4>
                  </div>
                  <div className="body-card-project-point">
                    <CheckedRoundIconTest className="body-card-point-icon" />
                    <h4>High-Impact</h4>
                  </div>
                </div>
              </div>
            }
            >
            <Meta
              description={
                <div className="body-card-solar-metrics">
                  <Col
                    xs={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    lg={{ span: 24 }}
                  >
                    <Col
                      xs={{ span: 8 }}
                      md={{ span: 8 }}
                      sm={{ span: 8 }}
                      lg={{ span: 8 }}
                      className="ant-col-solar-metric"
                    >
                      <Popover placement="bottomLeft" title="Energy Output" content="500 MW/year">
                        <div className="solar-metric-sphere">
                          <span className="solar-metric-shadow"></span>
                          <EnergyMetric className="energy-metric-icon" />  
                        </div>
                      </Popover>  
                    </Col>
                    <Col
                      xs={{ span: 8 }}
                      md={{ span: 8 }}
                      sm={{ span: 8 }}
                      lg={{ span: 8 }}
                      className="ant-col-solar-metric"
                    >
                      <Popover placement="bottomLeft"
                        title="Impact"
                        content="250 Co2 tonnes offset from atmosphere"
                      >
                        <div className="solar-metric-sphere">
                          <span className="solar-metric-shadow"></span>
                          <ImpactMetric className="impact-metric-icon" />  
                        </div>
                      </Popover>  
                    </Col>  
                    <Col
                      xs={{ span: 8 }}
                      md={{ span: 8 }}
                      sm={{ span: 8 }}
                      lg={{ span: 8 }}
                      className="ant-col-solar-metric"
                    >  
                      <Popover placement="bottomLeft" title="Construction Time" content="Operational after 1 year of construction.">
                        <div className="solar-metric-sphere">
                          <span className="solar-metric-shadow"></span>
                          <TimeMetric className="time-metric-icon" />  
                        </div>
                      </Popover>  
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      lg={{ span: 24 }}
                      className="ant-col-learn-more-btn"  
                    >  
                      <Button
                        className='solar-metric-learn-btn'
                        icon={<ArrowRight />}
                        iconPosition="end"
                      >
                        Learn More
                      </Button>    
                    </Col>  
                  </Col>
                    
                </div>
              }
            />
          </Card>
          </Col>
          <br /><br />
          <Col
            xs={{ span: 10 }}
            md={{ span: 10 }}
            sm={{ span: 10 }}
            lg={{ span: 10 }}
            className="ant-col-solar-img"
          >
            <Image
              src="/home/forest-tree-phase.png"
              width={600}
              height={600}
              alt="illustration reforestation"
              className="body-card-project-img"
            />
          </Col>
        </Col>
      </Row>

    </div>
  );
}
